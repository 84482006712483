import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Home from "./Pages/Home";


class App extends Component {
  componentDidMount(){
    document.title = "Goldenkey Window and Door Specialist"
  }

  render() {
    return (
      <BrowserRouter>
        <Header />
        <React.Fragment>
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </React.Fragment>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default App;
