import React from "react";

const Home = () => {
  return (
    <React.StrictMode>
      <div className="main-page-content">

        {/* <!-- ================================ Home =============================== --> */}
        <div id="home">
            <div className="home-content-main">
              <div className="table-cell">
                <div className="container">
                  <div className="row home-row">
                    <div className="col-md-12 col-sm-12">
                      <br /><br /><br /><br /><br /><br />
                      <div className="text-center">
                        <h3 className="is-full-width">
                          Quality windows and doors, professionally installed or repaired at competitive prices
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="gallery">
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/gallery/mmexport1688110673659.jpg"
                        alt=""
                      />
                    </div>
                    <div className="gallery-item">
                      <img
                        className="gallery-image"
                        src="assets/gallery/Image_2023050312422610.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row home-row">
                    <div className="col-md-12 col-sm-12">
                      <div className="text-center">
                        <h3 className="is-full-width">
                          Fensa registered company
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>

        {/* <!-- ================================ About =============================== --> */}
        <div id="about">
          <div className="about-content">
            <div className="love-grid text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>What we do</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        We specialise in the manufacture and installation of UPVC Windows and Doors. 
                        Our products transform the look of your home and provide unbeatable technical performance.
                      </p>
                      <br />
                      <br />
                      <div className="col-md-4 col-sm-6">
                        <a
                          className="about-link-1"
                          href="assets/docs/Goldenkey-products.pdf"
                          target="_blank"
                        >
                          Download Product Catalogue 1
                        </a>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <a
                          className="about-link-1"
                          href="assets/docs/Goldenkey-doors.pdf"
                          target="_blank"
                        >
                          Download Product Catalogue 2
                        </a>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <a
                          className="about-link-1"
                          href="assets/docs/Style.pdf"
                          target="_blank"
                        >
                          Download Style Catalogue
                        </a>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="work-counter text-center">
              <div className="counter">
                <div className="container">
                  <div className="row wow fadeIn">
                    <div className="col-md-4 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-pencil-square-o stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">20+</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Over 20 years of experence</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-crop stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">1000+</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>Over 1000 windows and doors installed</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                      <div className="work-statistics">
                        <i
                          className="fa fa-coffee stat-icon"
                          aria-hidden="true"
                        ></i>
                        <h3 className="Count">100</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>100% customer satisfaction</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ Products =============================== --> */}
        <div id="products">
          <div className="resume-content">
            <div className="resume-grid text-center">
              <div className="container">
                <div className="row main-row wow fadeIn">
                  <div className="col-md-12">
                    <div className="main-title text-center">
                      <h3>Products</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        We provide products to London and south England.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row love-row">
                  <div className="col-md-6 col-sm-12">
                    <div className="exp-details" data-wow-delay=".2s">
                      <div className="exp-hover"></div>
                      <div className="exp-main">
                        <i
                          className="fa fa-building exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>  
                            Double Glazed Windows
                        </h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <br />
                        <li>Casement Windows</li>
                        <li>Tilt and Turn Windows</li>
                        <li>Vertical Sliding Sash Windows</li>
                        <li>Fully Reversible Windows</li>
                        <li>Georgian Windows</li>
                        <li>Energy Rated Windows</li>
                        <li>Triple Glazed Windows</li>
                        <li>Sash Windows</li>
                        <li>Bay Windows</li>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="exp-details" data-wow-delay=".3s">
                      <div className="exp-hover"></div>
                      <div className="exp-main">
                        <i
                          className="fa fa-building exp-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>
                          UPVC Doors
                        </h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <br />
                        <li>Composite Doors</li>
                        <li>Aluminium Doors</li>
                        <li>French Doors</li>
                        <li>Patio Doors</li>
                        <li>Bi-Fold Doors</li>
                        <li>Single Doors</li>
                        <li>Porch</li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ Gallery =============================== --> */}
        <div id="gallery">
          <div className="work-content">
            <div className="work-grid">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-title text-center wow fadeIn">
                      <h3>Gallery</h3>
                      <div className="underline1"></div>
                      <div className="underline2"></div>
                      <p>
                        Here are some photos for projects completed by us in the past.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="gallery" style={{ paddingTop: "80px" }}>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_20230503124113.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_20230503124226.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242261.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242262.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242263.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242264.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242265.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242266.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242267.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242268.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_202305031242269.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_2023050312422610.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_20230625113806.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_20230625113802.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_20230625113751.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_20230625113747.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/Image_2023050312422611.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/mmexport1683541245697.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/mmexport1683541254747.jpg"
                      alt=""
                    />
                  </div>
                  <div className="gallery-item">
                    <img
                      className="gallery-image"
                      src="assets/gallery/mmexport1683541299069.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- ================================ Contact ========================== --> */}
        <div id="contact">
          <div className="contact-content">
            <div className="text-grid">
              <div className="text-grid-main">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <div className="main-title text-center wow fadeIn">
                        <h3>Contact Us</h3>
                        <div className="underline1"></div>
                        <div className="underline2"></div>
                        <p>
                          We are always open to discussing products and designs.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-grid">
              <div className="contact-form-details wow fadeIn">
                <div className="container">
                  <div className="row contact-info-row text-center wow fadeIn">
                    <div className="col-md-6 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".4s">
                        <i
                          className="fa fa-envelope contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Email</h3>
                        <a
                          href="mailto:goldenkeywindowandoor@gmail.com"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          goldenkeywindowandoor@gmail.com
                        </a>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 contact-colamn">
                      <div className="contact-col-info" data-wow-delay=".6s">
                        <i
                          className="fa fa-mobile contact-icon"
                          aria-hidden="true"
                        ></i>
                        <h3>Phone</h3>
                        <a
                          href="tel:8372876775"
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontSize: "17px",
                          }}
                        >
                          07908155077 or 07946146220
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
};

export default Home;
